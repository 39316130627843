import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
import { Topbar } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "topbar"
    }}>{`Topbar`}</h1>
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface ITopbarLangMenu extends IMenuContent {
  short: string; // no botão de idiomas deve aparecer uma abreviação do idioma
}

export interface ITopbarUserMenu {
  menu: IMenuContent[]; // menu que será exibido ao clicar no avatar
  user?: { email: string; name?: string }; // informações para compor o avatar
}

export interface TopbarProps {
  container: HTMLElement; // referência que será o container da topbar
  title: string; // título da topbar
  currency?: string; // exibe um indicador de moeda - opcional
  langMenu?: ITopbarLangMenu[]; // referente ao menu de idiomas - opcional
  userMenu?: ITopbarUserMenu; // referente ao menu do usuario - opcional
  backButton?: boolean; // botão voltar
}

export interface ITopbarConstructor {
  new (props: TopbarProps): ITopbar;
}

export interface ITopbar {
  readonly destroy: () => void; // destroi todos os elementos

  onLangSelected?: (id: string | number) => void; // evento disparado quando um item no menu de idiomas é selecionado
  onUserSelected?: (id: string | number) => void; // evento disparado quando um item no menu do usuário é selecionado
  onBack?: () => void; // disparado quando clica no botão de voltar
}
`}</code></pre>
    <Playground __position={0} __code={'() => {\n  const [topbar, setTopbar] = useState(null)\n  const topbarRef = useRef()\n  //\n  useEffect(() => {\n    if (topbarRef.current && !topbar) {\n      setTopbar(\n        new Topbar({\n          container: topbarRef.current,\n          backButton: true,\n          title: \'Pismo\',\n          icon:\n            \'https://e3ba6e8732e83984.cdn.gocache.net/uploads/image/file/808603/regular_58b823c9e9a8c4ca7656239b5fbd51a6.png\',\n          currency: \'USD\',\n          langMenu: [\n            { id: \'en\', label: \'Inglês\', short: \'en\', selected: true },\n            { id: \'pt\', label: \'Português\', short: \'pt\' },\n            { id: \'es\', label: \'Espanhol\', short: \'es\' },\n          ],\n          userMenu: {\n            user: {\n              name: \'Andy Harris\',\n              email: \'and@gmail.com\',\n            },\n            menu: [\n              {\n                id: \'user\',\n                label: {\n                  first: \'Andy Harris\',\n                  second: \'and@gmail\',\n                },\n                disabled: true,\n              },\n              { id: \'/reset\', label: \'Reset Password\' },\n              { id: \'/logout\', label: \'Logout\' },\n            ],\n          },\n        }),\n      )\n    }\n    //\n    return () => {\n      if (topbar) topbar.destroy()\n    }\n  }, [topbarRef.current, topbar])\n  //\n  return (\n    <div className=\"tw-flex\">\n      <div ref={topbarRef} />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      Topbar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [topbar, setTopbar] = useState(null);
        const topbarRef = useRef(); //

        useEffect(() => {
          if (topbarRef.current && !topbar) {
            setTopbar(new Topbar({
              container: topbarRef.current,
              backButton: true,
              title: 'Pismo',
              icon: 'https://e3ba6e8732e83984.cdn.gocache.net/uploads/image/file/808603/regular_58b823c9e9a8c4ca7656239b5fbd51a6.png',
              currency: 'USD',
              langMenu: [{
                id: 'en',
                label: 'Inglês',
                short: 'en',
                selected: true
              }, {
                id: 'pt',
                label: 'Português',
                short: 'pt'
              }, {
                id: 'es',
                label: 'Espanhol',
                short: 'es'
              }],
              userMenu: {
                user: {
                  name: 'Andy Harris',
                  email: 'and@gmail.com'
                },
                menu: [{
                  id: 'user',
                  label: {
                    first: 'Andy Harris',
                    second: 'and@gmail'
                  },
                  disabled: true
                }, {
                  id: '/reset',
                  label: 'Reset Password'
                }, {
                  id: '/logout',
                  label: 'Logout'
                }]
              }
            }));
          } //


          return () => {
            if (topbar) topbar.destroy();
          };
        }, [topbarRef.current, topbar]); //

        return <div className="tw-flex">
        <div ref={topbarRef} />
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      